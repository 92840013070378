<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="4">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm
                  @submit.prevent="submit"
                >
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    v-model="formData.email"
                    placeholder="Email"
                    autocomplete="email"
                    type="email"
                    required
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    v-model="formData.password"
                    autocomplete="current-password"
                    required
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" type="submit">Login</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import AuthService from '../../services/auth.service';

export default {
  name: 'Login',
  created() {},

  data() {
    return {
      formData: {email: "", password: ""}
    }
  },

  methods: {
    submit: function() {
      if (!this.formData.email) {
        return false;
      }

      if (!this.formData.password) {
        return false;
      }

      AuthService.login(this.formData).then( response => {
        this.$router.push({name: 'Global'});
      }).catch( error => {
        alert("Connexion impossible, veuillez verifier votre mot de passe")
      })
    }
  }
}
</script>
